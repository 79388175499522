import { RealEstateContainer } from '@client/components/Detail/Containers';
import {
  getDescription,
  getTitle,
} from '@client/components/SEO/DetailHeadTags';
import SocialMeta from '@client/components/SEO/DetailHeadTags/SocialMeta';
import Sitemap from '@client/components/SEO/Sitemap';
import {
  markPerformance,
  Marks,
} from '@client/utilities/performance/performanceMonitor';
import { AdvScripts, LiveRampScript } from '@sbt-web/adv';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { type ProfileQueryData } from '@sbt-web/networking';
import { applyContextToFunc } from '@sbt-web/request-id-handler';
import getPlexusInternalLinks from '../server/detail-requests/plexus';
import {
  getCategoryByFriendly,
  nullifyUndefinedProperties,
  readEnvironmentIdFromHeaders,
} from '@sbt-web/utils';
import { Theme } from '@shared/helpers/Themes';
import { getAdvertiserName } from '@shared/models/Advertiser';
import schemaOrg from '@shared/tools/seo/detail';
import Head from 'next/head';
import React, { useContext, useEffect, useState } from 'react';
import { fetchItem } from '../server/detail-requests/item';
import {
  getAdvertiserProfile,
  getFavoriteCounter,
  getShopData,
  getShopGoogleReviewsData,
  getTrustInfo,
} from '../server/detail-requests/subito-services';
import { type BasePageProps, GonePageProps, setStatusOnServer } from './_app';
import type { AdDetailLinks } from '@client/components/SEO/InternalLinks/types';
import { getErrorMessage } from '@tools/errorHelpers';
import { useAdDetailCommons } from '@client/components/Detail/Containers/useAdDetailCommons';
import { SkeletonWithAdvForAdView } from '@client/components/Detail/Containers/SkeletonWithAdv';
import {
  HTTPStatusCode,
  type ItemCategory,
  type AdItem,
  type FavCounter,
  type Shop,
  type ShopGoogleReviews,
  type TrustProfileInfo,
} from '@sbt-web/network/types';
import type { AdDetailContext } from '@client/components/Detail/Containers/common-props';
import { unwrap } from '@shared/helpers/Promise';

//Carousel style:
import '@sbt-web/carousel/style';
import Script from 'next/script';
import { LiraRunAdvScriptWrapperForAdview } from '@client/components/Adv/Lira/LiraRunAdvScriptWrapper/LiraRunAdvScriptWrapperForAdview';
import { adTypesToSlugMap } from '@tools/search/values';
import { extractSbtHeaders, searchItems } from '@sbt-web/network/server';

markPerformance(Marks.GLOBAL_RUN);

export type ImmobiliAdDetailPageProps = BasePageProps & {
  category: ItemCategory;
  internalLinks: AdDetailLinks | null;
  pageTitle: string;
  shop: Shop | null;
  shopGoogleReviews: ShopGoogleReviews | null;
  favoriteCounter: FavCounter | null;
  advertiserProfile: ProfileQueryData | null;
  trustInfo: TrustProfileInfo | null;
  promo: null;
  ad: AdItem;
  shippingCosts: null;
};

export default function AdDetail(props: ImmobiliAdDetailPageProps) {
  const {
    ad,
    pageTitle,
    internalLinks,
    shop,
    shopGoogleReviews,
    favoriteCounter,
    advertiserProfile,
    trustInfo,
  } = props;
  const categoryId = ad.category.id;
  const [newCreditAgricoleToggle, setNewCreditAgricoleToggle] = useState(false);
  const { optimizely } = useContext(OptimizelySubitoContext);

  useEffect(() => {
    optimizely?.onReady().then(() => {
      setNewCreditAgricoleToggle(
        optimizely.isFeatureEnabled('subito.web.adv.credit-agricole')
      );
    });
  }, [optimizely]);

  useAdDetailCommons({
    ad,
    trustPingEnabled: props.trustInfo?.presence.enabled,
  });

  return (
    <>
      <Head>
        <title key="page-title">{pageTitle}</title>
        <meta
          name="description"
          key="description"
          content={getDescription(ad)}
        />
        <meta
          name="robots"
          key="robots"
          content="index, follow, max-image-preview:large"
        />
        <link rel="canonical" key="canonical-url" href={ad.urls.default} />

        <script key="detail-schema" {...schemaOrg(ad)} />

        {SocialMeta({ adItem: ad })}
      </Head>

      <SkeletonWithAdvForAdView>
        <AdvScripts
          Script={Script}
          config={{
            includeOw: false,
            includeTam: false,
            includeAdSense: true,
            includePrebid: true,
            publicPath: '/static/script',
          }}
        />
        <LiveRampScript Script={Script} />
        <LiraRunAdvScriptWrapperForAdview ad={ad} />
        <RealEstateContainer
          ad={ad}
          categoryId={categoryId}
          internalLinks={internalLinks}
          shop={shop}
          shopGoogleReviews={shopGoogleReviews}
          favoriteCounter={favoriteCounter}
          advertiserProfile={advertiserProfile}
          trustInfo={trustInfo}
          newCreditAgricoleToggle={newCreditAgricoleToggle}
        />
      </SkeletonWithAdvForAdView>
      <Sitemap />
    </>
  );
}

const getProps = async (
  ctx: AdDetailContext
): Promise<
  | { props: ImmobiliAdDetailPageProps | GonePageProps }
  | { notFound: true }
  | { redirect: { destination: string; permanent: boolean } }
> => {
  const { query, req, res } = ctx;
  const { id } = query;

  if (!id) {
    return {
      notFound: true,
    };
  }

  let environmentId: string | undefined = undefined;

  try {
    if (req != null) {
      const eid = readEnvironmentIdFromHeaders(req);
      if (eid != null) {
        environmentId = eid;
      }
    }
  } catch (error) {
    console.error(
      'Could not set the Environment ID on the serverside,',
      getErrorMessage(error)
    );
  }

  const item = await fetchItem(id, environmentId, req);
  const category = getCategoryByFriendly(query.category);

  if (item == null) {
    const defaultAdType = category.defaultAdType;

    const categoryDefaultUrl = `/annunci-italia/${
      adTypesToSlugMap[defaultAdType]
    }/${category.friendly}/`;

    let gonePageSuggestions: AdItem[];
    try {
      const searchResponse = await searchItems(
        process.env.ANUBI_BASE_URL as string,
        {
          c: category.id,
          lim: '6',
          t: defaultAdType,
        },
        extractSbtHeaders(req),
        true
      );
      gonePageSuggestions = searchResponse.body.ads;
    } catch {
      gonePageSuggestions = [];
    }
    return {
      props: nullifyUndefinedProperties({
        ...setStatusOnServer(HTTPStatusCode.Gone, res),
        categoryLabel: category.label,
        categoryDefaultUrl,
        categoryId: category.id,
        items: gonePageSuggestions,
      }) as GonePageProps,
    };
  }

  // Augment the response:
  const [
    shopData,
    shopGoogleReviewsData,
    internalLinks,
    favoriteCounter,
    advertiserProfileResponse,
    trustInfoResponse,
  ] = await Promise.allSettled([
    getShopData(item.advertiser, res, req),
    getShopGoogleReviewsData(item.advertiser, res, req),
    getPlexusInternalLinks(
      {
        pageUrl: item.urls.default,
        category: item.category,
        geo: item.geo,
        baseQuery: `${item.subject} ${item.category.label} ${item.type.value.toLowerCase()} a ${item.geo.city?.value}`,
      },
      res
    ),
    getFavoriteCounter(item.urn, res, req),
    getAdvertiserProfile(item.advertiser.userId, res, req),
    getTrustInfo(item.advertiser, res, req),
  ]);

  const augmentResponse = {
    shop: unwrap(shopData),
    shopGoogleReviews: unwrap(shopGoogleReviewsData),
    internalLinks: unwrap(internalLinks),
    favoriteCounter: unwrap(favoriteCounter),
    advertiserProfile: unwrap(advertiserProfileResponse),
    trustInfo: unwrap(trustInfoResponse),
  };

  return {
    props: nullifyUndefinedProperties({
      ...augmentResponse,
      ...setStatusOnServer(HTTPStatusCode.OK, res),
      category: item.category,
      ad: item,
      shippingCosts: null,
      theme: Theme.Purple,
      promo: null,
      pageName: 'addetail',
      pageTitle: getTitle(
        item,
        getAdvertiserName(
          item.advertiser.type,
          augmentResponse.shop,
          augmentResponse.advertiserProfile
        )
      ),
    }),
  };
};

const wrappedGetProps = applyContextToFunc(getProps);

export { wrappedGetProps as getServerSideProps };
